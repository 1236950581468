@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../common/styles/abstracts/mixins";

a {
    color: black;
}

a:visited {
    color: black;
}

.sort-button-container {
    margin-left: auto;
}

.button-row {
    @include flex(row, flex-end, center);
    gap: 12px; 
}

.custom-grid {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;
    padding-top: 20px;
    padding-bottom: 36px;
    @include mq("tablet-wide") {
        grid-template-columns: repeat(2, 1fr);
    }
}

.main-page-wrapper {
    padding-bottom: 40px;
}

.on-documents {
    margin: 0;
    padding-bottom: 0;
    padding-top: 40px;
}

.documents-filters-dropdown {
    position: absolute;
    top: 374px;
    z-index: 1001;
    border-radius: 4px;
    border: 1px solid;
    border-bottom: none;
    border-color: $clr-gray-300;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    @include flex(row, flex-start, flex-start);

    background-color: $clr-white;

    @include mq("tablet", min) {
        top: 380px;
    }

    &__inner {
        @include flex(column, center, flex-start);
    }
}

.filter-icon {
    width: 20px;
    height: 20px;
}

@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../common/styles/abstracts/variables-pcg";
@import "../../../common/styles/abstracts/mixins";

.company-table-container {
    height: 100vh;
    padding: 30px;
    padding-right: 0px;
    padding-top: 0;

    @include mq(desktop-wide, min) {
        padding-right: 30px;
    }

    th {
        padding: 15px;
        color: $clr-black;
    }

    td {
        padding: 10px 10px 15px 15px;
    }

    &__customer_case-study {
        width: 100%;
    }

    &__domain {
        width: 100%;
    }

    &__hyperscaler {
        width: 100%;
    }

    &__name {
        margin-left: 4px;
        width: 100%;
        @include mq("desktop-wide", min) {
            padding-left: 0px;
        }
    }
}

.section-wrapper {
    padding: 0 5vw;
}

.company-title {
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    .text {
        font-size: 2rem;
    }
    .sub-result {
        font-size: 0.8rem;
        color: #ffea24;
    }
    margin-bottom: 15px;
}

.companies-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 40px;

    @include mq("tablet", min) {
        margin-bottom: 60px;
    }

    .title,
    .checkbox-container {
        padding-left: 25px;
    }
}

.checkbox {
    input {
        margin-right: 10px;
    }
}

.p-datatable {
    table {
        font-family: "Manrope";
        font-size: 12px;
    }
    thead {
        tr {
            th {
                background: transparent;
                width: 120px;
                .p-column-header-content {
                    width: 120px;
                }
            }
        }
    }

    tbody,
    .p-paginator {
        color: $clr-black;
        background: transparent;
        border: none;
    }
    .p-dropdown {
        background: transparent;
    }

    .p-datatable-tbody > tr {
        color: $clr-black;
    }
}

.p-datatable .p-datatable-tbody > tr {
    background: none;
}

.p-inputtext {
    color: $clr-black;
}

.p-datatable-wrapper {
    tbody {
        tr {
            height: 70px;
        }
        td {
            max-height: 20px;
            overflow: hidden;
        }
    }
}

.columns-search-box {
    @include flex(row, flex-end, center);
    margin-top: 32px;
    margin-bottom: 24px;
    @include mq("desktop", min) {
        margin-bottom: 12px;
        margin-top: 60px;
        @include flex(row, flex-start, center);
    }
    @include mq("tablet", min) {
        @include flex(row, flex-end, center);
    }
    @include mq("tablet-wide", min) {
        @include flex(row, flex-start, center);
    }
}

.columns-heading {
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0;

    @include mq("tablet", min) {
        padding-left: 38.4px;
        padding-right: 38.4px;
    }
    @include mq("tablet-wide", min) {
        padding-left: 51px;
        padding-right: 51px;
    }
    @include mq("desktop", min) {
        padding-left: 62.4px;
        padding-right: 62.4px;
    }
    @include mq("desktop-wide", min) {
        padding-left: 72px;
        padding-right: 72px;
    }
    @include mq("desktop-wide-helper", min) {
        padding-left: 82px;
        padding-right: 82px;
    }
}

.columns-heading {
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0;
}

.multiselect-column {
    max-height: 70px;
    padding: 10px;
    margin: 0 !important;
}

.select-field {
    width: 100%;
}

.input {
    height: 5vh;
}

.pi-question-circle {
    color: #e88543;
}

.pi-check-circle {
    color: #069e48;
}

.pagination-top {
    margin-left: auto;
}

.pagination-bottom {
    margin-top: 40px;
}

.pagination-top {
    margin-left: auto;
}

.pagination-bottom {
    margin-top: 40px;
}

.column_name {
    position: relative;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 8% 82% 10%;
}

table {
    td {
        vertical-align: inherit;
    }
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead {
    background-color: #e6e9ec;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-color: #dce0e5;
}

.p-row-odd {
    background-color: #e6e9ec !important;
}

.pi-sort-alt,
.pi-sort-alpha-down,
.pi-sort-alpha-down-alt {
    color: #929fae;
}

.p-datatable .p-datatable-thead > tr > th {
    border-color: #dce0e5;
}

.hubspot-pcg {
    height: 18px;
    width: 16px;
}

.is-match {
    text-align: right;
    padding-left: 4px;

    &__icon {
        height: 14px;
        width: 12px;
    }
}

.sort-icon {
    margin-left: 4px;
    height: 12px;
    width: 12px;
}

.heading {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    margin-left: 0px;

    &__tag {
        margin-bottom: 0px;
    }

    &__title {
        position: absolute;
        bottom: 0px;
        width: max-content;
    }
}

.item {
    order: 2;
    position: relative;
    margin-bottom: 0px;
}

.field:not(:last-child) {
    margin-bottom: 0px;
}

.search {
    &__container {
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;

        @include mq("tablet", min) {
            max-width: 300px;
        }

        @include mq("desktop-wide", min) {
            max-width: 360px;
        }
    }

    &__field{
        width: 100%;
    }

}

.title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
    @include mq("tablet", min) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 60px;
    }
    width: 100%;
    margin-top: 40px;
}
.btn-filters {
    background-color: #e6e9ec;
    width: 181px;
    height: 40px;
    padding: 10px 12px 10px 12px;
    font-weight: 600;
    @include flex(row, center, center);
    &.btn-filters-active {
        background-color: $clr-tertiary-500;
    }
}

.filters-text {
    padding-left: 8px;
    font-size: 14px;
}
.number-span {
    padding-left: 3px;
    font-weight: 600;
}

.number {
    display: inline-block;
    min-width: 12px;
    @include mq("tablet-wide", min) {
        min-width: 16px;
    }
}

.filters-screen {
    text-transform: uppercase;
}

.filters-dropdown {
    position: absolute;
    top: 364px;
    // right: 5vw;
    z-index: 1001;
    border-radius: 4px;
    border: 1px solid;
    border-bottom: none;
    border-color: $clr-gray-300;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    @include flex(row, flex-start, flex-start);

    background-color: $clr-white;

    @include mq("tablet", min) {
        top: 303px;
    }

    &__inner {
        @include flex(column, center, flex-start);
    }
}
.multi-filters {
    padding: 14px 16px 14px 16px;
    @include mq("tablet-wide", min) {
        padding: 12px 10px;
    }
}

.reset-button {
    width: 20px;
    height: 20px;
}

@media print, screen and (min-width: 768px) {
    .columns:not(.is-desktop) {
        display: flex;
    }
}

.btn__filter__insights {
    font-weight: 600;
    margin: 0 !important;
}

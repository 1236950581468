@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../common/styles/abstracts/mixins";

.dropdown {
    display: inline-block;
}

.button-sort {
    @include flex(row, center, center);
    background-color: #E6E9EC;
    color: black;
    padding: 10px 12px;
    border: none;
    cursor: pointer;
    border-radius: 0px;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 181px;
    z-index: 1;
    gap: 8px;

    img {
        width: 20px;
        height: 20px;
    }
}

.dropdown-content {
    position: absolute;
    right: 0px;
    background-color: #FFFFFF;
    font-family: "Sora", sans-serif;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px #00000026;
    border: 1px solid #DCE0E5;
    padding-top: 16px;
    padding-bottom: 0px;
    margin-top: 12px;
    width: 260px;
}

.dropdown-items {
    @include flex();
    padding-right: 12px;
    padding-left: 12px;
    gap: 8px;

    button { 
        @include flex(row, center, center);
        background-color: #E6E9EC;
        color: #000000;
        margin-bottom: 16px;
        width: 114px;
        padding: 8px 12px;
        border-bottom: 1px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        font-family: "Manrope", sans-serif;

        img {
            width: 14px;
            height: 14px;
            margin-right: 3px;
        }
    }
}

.dropdown-item-list {
    border-bottom: 1px solid #DCE0E5;
    padding: 14px 16px;
    color: #000000;
    font-family: "Manrope", sans-serif;
    cursor: pointer;
    margin: 0px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.dropdown-item.selected {
    background-color: #ffea24; 
}

.dropdown-item-list.selected {
    background-color: #FDF7C9; 
}

.button-sort.selected {
    background-color: #FFEA24;
}

@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../styles/abstracts/mixins";

.card {
    @include flex(column, normal, normal);
    background-color: white;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 0 !important;
    box-shadow: none !important;
    position: relative;

    border: 1px $clr-gray-300 solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    &.featured {
        background-color: $clr-gray-100;
    }

    &__type {
        font-family: "Sora", sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        margin-bottom: 8px;
        margin-left: 16px;
        margin-top: 16px;
        font-weight: 600;
        color: $clr-white;
        padding: 4px 20px;
        background: $clr-tertiary-500;
        color: $clr-on-tertiary-500;
        transition: 0.3s;
        transform-origin: left;
        cursor: pointer;
        position: relative;

        &:hover {
            padding-left: 15px;
        }

        &.policy {
            background-color: $clr-tertiary-500;

            &:visited {
                color: $clr-black;
            }
        }

        &.process {
            background-color: $clr-primary-500;
            color: $clr-white;

            &:visited {
                color: $clr-white;
            }
        }

        &.guide {
            background-color: $clr-secondary-500;

            &:visited {
                color: $clr-black;
            }
        }

        &.checklist {
            background-color: $clr-quaternary-500;
            color: $clr-white;

            &:visited {
                color: $clr-white;
            }
        }

        &.template {
            background-color: $clr-secondary-700;

            &:visited {
                color: $clr-black;
            }
        }

        &.presentation {
            background-color: $clr-quaternary-200;

            &:visited {
                color: $clr-black;
            }
        }

        &.kpis {
            background-color: $clr-tertiary-200;

            &:visited {
                color: $clr-black;
            }
        }

        &.workbook {
            background-color: $clr-primary-300;
            color: $clr-white;

            &:visited {
                color: $clr-white;
            }
        }

        &.database {
            background-color: $clr-primary-200;

            &:visited {
                color: $clr-black;
            }
        }

        &.knowledge {
            background-color: $clr-secondary-100;

            &:visited {
                color: $clr-black;
            }
        }
    }

    &__content {
        @include flex(column, normal, normal);
        margin-left: 16px;
        border-left: 7px solid;
        padding: 0px 20px 0px 16px;
        flex: 1;

        margin-top: 20px;

        &.policy {
            border-left-color: $clr-tertiary-500;
        }

        &.process {
            border-left-color: $clr-primary-500;
        }

        &.guide {
            border-left-color: $clr-secondary-500;
        }

        &.checklist {
            border-left-color: $clr-quaternary-500;
        }

        &.template {
            border-left-color: $clr-secondary-700;
        }

        &.presentation {
            border-left-color: $clr-quaternary-200;
        }

        &.kpis {
            border-left-color: $clr-tertiary-200;
        }

        &.workbook {
            border-left-color: $clr-primary-300;
        }

        &.database {
            border-left-color: $clr-primary-200;
        }

        &.knowledge {
            border-left-color: $clr-secondary-100;
        }

        &__wrapper {
            @include flex(column, start, start);
            gap: 16px;
            height: 100%;
        }

        &__metadata {
            font-size: 14px;
            @include flex(row, start, center, wrap);

            &__id {
                color: $clr-black;
            }

            &__dot {
                padding-left: 8px;
                padding-right: 8px;
            }
        }

        &__main {
            @include flex(column, start, start);
            gap: 4px;
            color: $clr-black;

            &__title {
                margin: 0;
            }
        }

        &__tags {
            @include flex(row, flex-start, flex-end);
            flex: 1 1;
            gap: 8px;
        }
    }

    &__featuredTag {
        max-height: 49px;
        max-width: 116px;
        position: absolute;
        top: 16px; // Align to the top
        right: -8px; // Align to the right
    }

    &__link {
        opacity: 0;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.badge-new {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 72px;
    height: 72px;

    @include mq("tablet", min) {
        width: 88px;
        height: 88px;
    }
}

.badge-updated {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 96px;
    height: 96px;

    @include mq("tablet", min) {
        top: -12px;
        right: -11px;
        width: 124px;
        height: 124px;
    }
}
